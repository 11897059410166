<template>
  <div
    style="padding-top: 80px"
    class="container1 mx-auto text-center desktop:text-left desktop:px-0 px-10"
    :id="title"
  >
    <div :class="[subTitle ? 'mb-10' : 'mb-40']" style="line-height: 1">
      <span class="mr-10 font-semibold" style="font-size: 32px">{{
        title
      }}</span>
      <span class="text-primary font-black" style="font-size: 56px">{{
        index
      }}</span>
    </div>
    <div class="mb-40 text-666" v-if="subTitle">{{ subTitle }}</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    index: String,
    subTitle: String,
  },
};
</script>

<style lang="less" scoped>
.container1 {
  max-width: 800px;
}
</style>