<template>
  <div class="pb-40">
    <!-- 导航 -->
    <div
      class="hidden desktop:block text-center text-white bg-primary"
      style="padding: 0 8.3%; height: 72px"
    >
      <div class="float-right h-full flex items-center">
        <a-space v-if="isLogin" align="center" :size="20">
          <MessageBadge />

          <AvatarMenu :tracks="tracks" @cancel="cancel">
            <a-badge :dot="isMessage" style="margin-top: 10px">
              <Avatar />
            </a-badge>
          </AvatarMenu>
        </a-space>
        <div class="h-full flex items-center" v-else>
          <div
            class="
              flex
              justify-around
              border-eee border
              rounded-full
              py-2
              px-10
              text-sm
            "
            style="width: 120px"
          >
            <router-link to="/auth/login">
              <span class="text-white">登录</span>
            </router-link>
            <span>I</span>
            <router-link to="/auth/register">
              <span class="text-white">注册</span>
            </router-link>
          </div>
        </div>
      </div>

      <div class="container mx-auto">
        <a-row>
          <a-col :span="3" v-for="item in nav" :key="item">
            <div
              class="
                border-b-4
                item
                border-transparent
                cursor-pointer
                inline-block
              "
              style="height: 72px; line-height: 72px"
              :class="{ active: item === active }"
              @click="navTo(item)"
            >
              {{ item }}
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div
      class="desktop:hidden text-left text-white bg-primary flex items-center"
      style="padding: 0 4%; height: 44px"
    >
      <a-dropdown>
        <a-icon type="menu" />
        <a-menu slot="overlay">
          <a-menu-item v-for="item in nav" :key="item">
            <div
              class="item1 border-transparent cursor-pointer inline-block"
              :class="{ active: item === active }"
              @click="navTo(item)"
            >
              {{ item }}
            </div>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>

    <!-- 首页 -->
    <div id="首页">
      <div
        style="background-image: linear-gradient(to bottom, #3462ec, #222324)"
        class="relative desktop:inline-block hidden"
      >
        <img src="@/assets/icons/company/banner1.png" alt="" />
        <div
          class="
            absolute
            top-0
            left-0
            w-full
            h-full
            flex
            justify-center
            items-center
            flex-col
            text-white
          "
        >
          <img
            class="mb-40"
            src="@/assets/icons/company/title_com.svg"
            alt=""
          />
          <div v-if="state === 0" class="text-xl">
            报名通道{{ times[0][0] | getDate }}正式开启
          </div>
          <div
            v-if="state === 1"
            @click="join()"
            class="
              cursor-pointer
              bg-primary
              border border-white
              px-20
              py-1.5
              rounded
            "
          >
            我要参赛
          </div>
        </div>
      </div>
      <div
        style="background-image: linear-gradient(to bottom, #3462ec, #222324)"
        class="relative inline-block desktop:hidden"
      >
        <img
          style="height: 280px"
          src="@/assets/icons/company/banner1.png"
          alt=""
        />
        <div
          style="width: 80%"
          class="
            absolute
            top-0
            left-1/2
            transform
            -translate-x-1/2
            w-full
            h-full
            flex
            justify-center
            items-center
            flex-col
            text-white
          "
        >
          <img
            class="mb-40"
            src="@/assets/icons/company/title_phone.svg"
            alt=""
          />
          <div v-if="state === 0" class="text-xl">
            报名通道{{ times[0][0] | getDate }}正式开启
          </div>
          <div
            v-if="state === 1"
            @click="join1"
            class="
              cursor-pointer
              bg-primary
              border border-white
              px-20
              py-1.5
              rounded
            "
          >
            我要参赛
          </div>
        </div>
      </div>
    </div>
    <!-- 活动状态 -->
    <div class="py-10 bg-background -mt-1 hidden">
      <div
        v-if="!this.state"
        class="
          h-full
          flex flex-col
          desktop:flex-row
          justify-center
          items-center
        "
      >
        <span class="desktop:mr-10 desktop:mb-0 mb-10 text-xl"
          >距离报名通道开启还有</span
        >
        <a-space class="text-white">
          <div class="h-40 bg-primary rounded px-20 py-1.5">
            <span class="text-xl font-semibold">{{ time.day }}</span>
            <span class="text-xs">天</span>
          </div>
          <div class="h-40 bg-primary rounded px-20 py-1.5">
            <span class="text-xl font-semibold">{{ time.hour }}</span>
            <span class="text-xs">时</span>
          </div>
          <div class="h-40 bg-primary rounded px-20 py-1.5">
            <span class="text-xl font-semibold">{{ time.minute }}</span>
            <span class="text-xs">分</span>
          </div>
          <div class="h-40 bg-primary rounded px-20 py-1.5">
            <span class="text-xl font-semibold">{{ time.second }}</span>
            <span class="text-xs">秒</span>
          </div>
        </a-space>
      </div>
      <div
        v-else-if="this.state === 1"
        class="
          h-full
          flex flex-col
          desktop:flex-row
          justify-center
          items-center
        "
      >
        <span class="desktop:mr-10 desktop:mb-0 mb-10 text-xl"
          >距离参赛报名通道关闭还有</span
        >
        <a-space class="text-white">
          <div class="h-40 bg-primary rounded px-20 py-1.5">
            <span class="text-xl font-semibold">{{ time.day }}</span>
            <span class="text-xs">天</span>
          </div>
          <div class="h-40 bg-primary rounded px-20 py-1.5">
            <span class="text-xl font-semibold">{{ time.hour }}</span>
            <span class="text-xs">时</span>
          </div>
          <div class="h-40 bg-primary rounded px-20 py-1.5">
            <span class="text-xl font-semibold">{{ time.minute }}</span>
            <span class="text-xs">分</span>
          </div>
          <div class="h-40 bg-primary rounded px-20 py-1.5">
            <span class="text-xl font-semibold">{{ time.second }}</span>
            <span class="text-xs">秒</span>
          </div>
        </a-space>
      </div>
      <div
        v-else
        class="
          h-full
          flex
          justify-center
          items-center
          font-medium
          text-xl text-primary
        "
      >
        <img class="mr-10" src="@/assets/icons/company/clock.svg" alt="" />
        <!-- <span v-if="state === 1">申请材料征集中...</span> -->
        <span v-if="state === 2">材料初审中...</span>
        <span v-if="state === 3">专家复审中...</span>
        <span v-if="state === 4">现场路演及决赛中...</span>
        <span v-if="state === 5">获奖名单公告中...</span>
        <span v-if="state === 6">颁奖中...</span>
        <span v-if="state === 7">颁奖结束</span>
      </div>
    </div>
    <!-- 活动简介 -->
    <Wrapper title="活动简介" index="01">
      <div class="p-20 bg-background relative text-left">
        <div
          class="absolute bottom-20 right-20 border-second"
          style="
            border-width: 50px;
            border-top-color: transparent;
            border-left-color: transparent;
          "
        ></div>
        <div class="relative z-10">
          <div class="mb-20">
            为深入贯彻习近平总书记关于持续提升工业互联网创新能力的指示精神，落实国务院深化“互联网+先进制造业”发展工业互联网的指导意见，贯彻上海市委、市政府《关于全面推进上海城市数字化转型的意见》，按照《推动工业互联网创新升级，实施“工赋上海”三年行动计划（2020-2022年）》部署安排，全力推进上海工业互联网2.0创新发展，全面打响“工赋上海”品牌，发挥领军企业和先锋人物的标杆引领和示范作用，分享工业互联网经验成果，构建更加开放、合作、交流、学习的产业发展生态，实现工业化与信息化在更广范围、更深程度、更高水平上融合发展。
          </div>
          <div>
            <p>
              为推动上海市工业化和信息化融合水平保持全国第一梯队，抓典型，树标杆，强导向，推动工业互联网迈上更高台阶，在全市范围内开展「工赋风云榜」--工赋上海2021大型系列年度评选活动。
            </p>
            <p>
              评选从风云企业和先锋人物两个维度入手，挖掘各个行业和领域的创新实践企业、示范项目及代表人物。本次评选活动突出政策引导，广泛参与，营造良好的标杆引领效应。
            </p>
            <p>
              「工赋风云榜」评选每年举行一次，面向社会各界开展奖项征集，获奖企业和人物将在2022年工业人嘉年华上参加颁奖仪式，并获得后续的广泛宣传。
            </p>
          </div>
        </div>
      </div>
    </Wrapper>
    <!-- 奖项设置 -->
    <Wrapper
      title="奖项设置"
      index="02"
      subTitle="（设立企业奖和人物奖两大方向，细分为7个子赛道）"
    >
      <div class="mb-40">
        <div class="mb-20 text-center">
          <div class="inline-block relative">
            <img
              style="width: 164px"
              src="@/assets/icons/company/获奖设置.png"
              alt=""
            />
            <div
              class="
                absolute
                w-full
                h-full
                top-0
                left-0
                flex flex-col
                justify-center
                items-center
                text-white
              "
            >
              <span class="font-bold" style="text-shadow: 0 2px 4px #000"
                >年度风云企业</span
              >
              <span style="font-size: 32px" class="text-second font-bold"
                >10</span
              >
              <span class="text-xs">另设提名奖5个</span>
            </div>
          </div>
        </div>
        <a-space :size="20" direction="vertical" class="w-full">
          <a-row
            :gutter="20"
            v-for="item in trackList.slice(0, 4)"
            :key="item.id"
          >
            <a-col :xs="24" :sm="24" :md="6" class="mb-20 desktop:mb-0">
              <div
                style="line-height: 92px"
                class="
                  bg-primary
                  text-white text-center
                  rounded
                  text-xl
                  font-bold
                "
              >
                {{ item.title }}
              </div>
            </a-col>
            <a-col :xs="24" :sm="24" :md="18">
              <div
                style="min-height: 92px"
                class="
                  text-left
                  h-full
                  px-20
                  py-10
                  bg-background
                  relative
                  overflow-hidden
                  flex
                  items-center
                "
              >
                {{ item.content }}
                <div
                  v-if="
                    (state === 1 &&
                      tracks.find((track) => track.id === item.id) &&
                      [0, 1].includes(
                        tracks.find((track) => track.id === item.id).status
                      )) ||
                    (state === 1 &&
                      !tracks.find((track) => track.id === item.id))
                  "
                  @click="
                    join({
                      id: item.id,
                      isJoined: tracks.find((track) => track.id === item.id),
                    })
                  "
                  class="
                    absolute
                    bottom-0
                    right-0
                    opacity-0
                    transition
                    cursor-pointer
                    hidden
                    desktop:block
                  "
                  style="width: 96px; height: 40px"
                >
                  <div
                    class="
                      bg-primary
                      transform
                      origin-bottom-right
                      -skew-x-12
                      absolute
                      top-0
                      right-0
                      w-full
                      h-full
                    "
                  ></div>
                  <div
                    class="
                      z-10
                      absolute
                      top-0
                      right-0
                      w-full
                      h-full
                      text-center text-white text-sm
                    "
                    style="line-height: 40px"
                  >
                    <span v-if="tracks.find((track) => track.id === item.id)">
                      <span
                        v-if="
                          tracks.find((track) => track.id === item.id)
                            .status === 1
                        "
                        >已参赛</span
                      >
                      <span
                        v-else-if="
                          tracks.find((track) => track.id === item.id)
                            .status === 0
                        "
                        >完善资料</span
                      >
                    </span>
                    <span v-else>我要参赛</span>
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
        </a-space>
      </div>
      <div>
        <div class="mb-20 text-center">
          <div class="inline-block relative">
            <img
              style="width: 164px"
              src="@/assets/icons/company/获奖设置.png"
              alt=""
            />
            <div
              class="
                absolute
                w-full
                h-full
                top-0
                left-0
                flex flex-col
                justify-center
                items-center
                text-white
              "
            >
              <span class="font-bold" style="text-shadow: 0 2px 4px #000"
                >年度先锋人物</span
              >
              <span style="font-size: 32px" class="text-second font-bold"
                >10</span
              >
              <span class="text-xs">另设提名奖5个</span>
            </div>
          </div>
        </div>
        <a-space :size="20" direction="vertical" class="w-full">
          <a-row :gutter="20" v-for="item in trackList.slice(4)" :key="item.id">
            <a-col :xs="24" :sm="24" :md="6" class="mb-20 desktop:mb-0">
              <div
                style="line-height: 92px"
                class="
                  bg-primary
                  text-white text-center
                  rounded
                  text-xl
                  font-bold
                "
              >
                {{ item.title }}
              </div>
            </a-col>
            <a-col :xs="24" :sm="24" :md="18">
              <div
                style="min-height: 92px"
                class="
                  text-left
                  h-full
                  px-20
                  py-10
                  bg-background
                  relative
                  overflow-hidden
                  flex
                  items-center
                "
              >
                {{ item.content }}
                <div
                  v-if="
                    (state === 1 &&
                      tracks.find((track) => track.id === item.id) &&
                      [0, 1].includes(
                        tracks.find((track) => track.id === item.id).status
                      )) ||
                    (state === 1 &&
                      !tracks.find((track) => track.id === item.id))
                  "
                  @click="
                    join({
                      id: item.id,
                      isJoined: tracks.find((track) => track.id === item.id),
                    })
                  "
                  class="
                    absolute
                    bottom-0
                    right-0
                    opacity-0
                    transition
                    cursor-pointer
                    hidden
                    desktop:block
                  "
                  style="width: 96px; height: 40px"
                >
                  <div
                    class="
                      bg-primary
                      transform
                      origin-bottom-right
                      -skew-x-12
                      absolute
                      top-0
                      right-0
                      w-full
                      h-full
                    "
                  ></div>
                  <div
                    class="
                      z-10
                      absolute
                      top-0
                      right-0
                      w-full
                      h-full
                      text-center text-white text-sm
                    "
                    style="line-height: 40px"
                  >
                    <span v-if="tracks.find((track) => track.id === item.id)">
                      <span
                        v-if="
                          tracks.find((track) => track.id === item.id)
                            .status === 1
                        "
                        >已参赛</span
                      >
                      <span
                        v-else-if="
                          tracks.find((track) => track.id === item.id)
                            .status === 0
                        "
                        >完善资料</span
                      >
                    </span>
                    <span v-else>我要参赛</span>
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
        </a-space>
      </div>
    </Wrapper>
    <!-- 大赛进程 -->
    <Wrapper title="大赛进程" index="03">
      <div
        class="
          mb-40
          flex flex-col
          desktop:flex-row
          justify-between
          items-center
        "
      >
        <div
          style="width: 220px; height: 134px; background: #07bdea"
          class="relative rounded text-white mb-40 desktop:mb-0"
        >
          <div class="w-full h-full flex flex-col justify-center items-center">
            <span class="mb-10">自荐/推荐申请材料征集</span>
            <span class="text-xl font-semibold"
              >{{ times1[0][0] | getDate }}-{{ times1[0][1] | getDate }}</span
            >
          </div>
          <div class="absolute bottom-10 right-10">
            <img src="@/assets/icons/进程1.svg" alt="" />
          </div>
        </div>
        <img
          class="hidden desktop:inline-block"
          src="@/assets/icons/company/箭头.svg"
          alt=""
        />
        <div
          style="width: 220px; height: 134px; background: #3462ec"
          class="relative rounded text-white mb-40 desktop:mb-0"
        >
          <div class="w-full h-full flex flex-col justify-center items-center">
            <span class="mb-10">材料初审</span>
            <span class="text-xl font-semibold"
              >{{ times1[1][0] | getDate }}-{{ times1[1][1] | getDate }}</span
            >
          </div>
          <div class="absolute bottom-10 right-10">
            <img src="@/assets/icons/进程2.svg" alt="" />
          </div>
        </div>
        <img
          class="hidden desktop:inline-block"
          src="@/assets/icons/company/箭头.svg"
          alt=""
        />
        <div
          style="width: 220px; height: 134px; background: #ff6242"
          class="relative rounded text-white"
        >
          <div class="w-full h-full flex flex-col justify-center items-center">
            <span class="mb-10">专家复审</span>
            <span class="text-xl font-semibold"
              >{{ times1[2][0] | getDate }}-{{ times1[2][1] | getDate }}</span
            >
          </div>
          <div class="absolute bottom-10 right-10">
            <img src="@/assets/icons/进程3.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="flex justify-between flex-col desktop:flex-row items-center">
        <div
          style="width: 220px; height: 134px; background: #26468b"
          class="relative rounded text-white mb-40 desktop:mb-0"
        >
          <div class="w-full h-full flex flex-col justify-center items-center">
            <span class="mb-10">现场路演及决赛</span>
            <span class="text-xl font-semibold"
              >{{ times1[3][0] | getDate }}-{{ times1[3][1] | getDate }}</span
            >
          </div>
          <div class="absolute bottom-10 right-10">
            <img src="@/assets/icons/进程4.svg" alt="" />
          </div>
        </div>
        <img
          class="hidden desktop:inline-block"
          src="@/assets/icons/company/箭头.svg"
          alt=""
        />
        <div
          style="width: 220px; height: 134px; background: #ffdc09"
          class="relative rounded text-white mb-40 desktop:mb-0"
        >
          <div class="w-full h-full flex flex-col justify-center items-center">
            <span class="mb-10">网上公示评选名单</span>
            <span class="text-xl font-semibold"
              >{{ times1[4][0] | getDate }}-{{ times1[4][1] | getDate }}</span
            >
          </div>
          <div class="absolute bottom-10 right-10">
            <img src="@/assets/icons/进程5.svg" alt="" />
          </div>
        </div>
        <img
          class="hidden desktop:inline-block"
          src="@/assets/icons/company/箭头.svg"
          alt=""
        />
        <div
          style="width: 220px; height: 134px; background: #00c9f2"
          class="relative rounded text-white"
        >
          <div
            class="
              w-full
              h-full
              flex flex-col
              justify-center
              items-center
              px-20
              text-center
            "
          >
            <span class="mb-10">颁奖盛典</span>
            <span class="text-xl font-semibold">2022工业人嘉年华现场</span>
          </div>
          <div class="absolute bottom-10 right-10">
            <img src="@/assets/icons/进程6.svg" alt="" />
          </div>
        </div>
      </div>
    </Wrapper>
    <!-- 组织架构 -->
    <Wrapper title="组织架构" index="04">
      <div class="text-center">
        <div class="mb-40">
          <div class="mb-10 text-primary font-semibold text-xl">指导单位</div>
          <div style="line-height: 26px">上海市经济和信息化委员会</div>
          <div style="line-height: 26px">工业互联网产业联盟</div>
        </div>
        <div class="mb-40">
          <div class="mb-10 text-primary font-semibold text-xl">主办单位</div>
          <div style="line-height: 26px">上海市工业互联网协会</div>
          <div style="line-height: 26px">人民网上海频道</div>
          <div style="line-height: 26px">工业互联网产业联盟上海分联盟</div>
        </div>
        <div class="mb-40">
          <div class="mb-10 text-primary font-semibold text-xl">协办单位</div>
          <div style="line-height: 26px">
            工业互联网创新中心（上海）有限公司
          </div>
          <div style="line-height: 26px">中国信通院华东分院</div>
          <div style="line-height: 26px">上海市工业经济联合会</div>
          <div style="line-height: 26px">上海市企业联合会</div>
          <div style="line-height: 26px">上海民营经济研究会</div>
          <div style="line-height: 26px">上海市电力行业协会</div>
          <div style="line-height: 26px">上海医药行业协会</div>
          <div style="line-height: 26px">上海市电力行业协会</div>
          <div style="line-height: 26px">上海市集成电路行业协会</div>
          <div style="line-height: 26px">上海市通信制造业行业协会</div>
          <div style="line-height: 26px">上海市信息服务业行业协会</div>
          <div style="line-height: 26px">上海市家用纺织品行业协会</div>
          <div style="line-height: 26px">上海市热处理行业协会</div>
          <div style="line-height: 26px">上海市新材料协会</div>
          <div style="line-height: 26px">上海塑料行业协会</div>
          <div style="line-height: 26px">上海市铸造行业协会</div>
          <div style="line-height: 26px">上海有色金属行业协会</div>
          <div style="line-height: 26px">上海市焊接行业协会</div>
        </div>
        <!-- <div>
          <div class="mb-10 text-primary font-semibold text-xl">支持单位</div>
          <div style="line-height: 26px">相关媒体</div>
        </div> -->
      </div>
    </Wrapper>
    <!-- 评选方法 -->
    <Wrapper title="评选方法" index="05">
      <div class="p-20 bg-background rounded text-left">
        <div class="relative">
          <ul class="mb-20">
            <li class="mb-20">
              <div class="inline-block w-10 h-10 rounded-full bg-primary"></div>
              评选对象为在本市工商行政管理部门注册，具有法人地位的本市各类所有制并在工业互联网领域做出突出贡献的企业及企业人物。2020年已入选的企业及个人不再参评。
            </li>
            <li class="mb-20">
              <div class="inline-block w-10 h-10 rounded-full bg-primary"></div>
              评选标准方面上企业类评选标准将凸显技术先进性、应用创新性、方案价值性、实践方法论可推广性，参考承接国家工业互联网创新发展试点示范情况和行业贡献；人物类评选标准将通过思想前瞻性、创新性、贡献度及行业影响力等维度展开。
            </li>
            <li class="mb-20">
              <div class="inline-block w-10 h-10 rounded-full bg-primary"></div>
              评选活动本着公平、公开、公正的原则，采用自荐、推荐方式进行，材料经「工赋风云榜」评选组委会受理初审后，提交专家组复审、路演终审，形成最终获奖名单后进行公示，获奖企业和人物名单将于2022年工业人共振嘉年华上进行颁奖。
            </li>
            <li>
              <div class="inline-block w-10 h-10 rounded-full bg-primary"></div>
              对评选如有问题，请联系工赋骑士。
            </li>
          </ul>
          <div class="desktop:text-center font-semibold">
            <div>联系人：刘翊尊</div>
            <div>联系电话：13838160215</div>
          </div>
          <div class="absolute bottom-0 right-0">
            <img
              class="hidden desktop:inline-block"
              style="width: 108px"
              src="@/assets/icons/aaa.png"
              alt=""
            />
            <img
              class="desktop:hidden"
              style="width: 60px"
              src="@/assets/icons/aaa.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </Wrapper>
    <!-- 获奖公告 -->
    <Wrapper title="获奖公告" index="06">
      <div>
        <div
          class="hidden desktop:block p-20 mb-40 bg-background rounded relative"
        >
          <div class="mb-40">
            <div class="text-center" style="margin-bottom: 30px">
              <div
                class="
                  inline-block
                  py-10
                  px-20
                  text-second
                  bg-black
                  rounded-full
                "
              >
                「年度风云企业获奖名单」
              </div>
            </div>
            <a-row :gutter="40" class="text-sm">
              <a-col
                :span="6"
                style="height:120px"
                class="mb-20"
                v-for="item in company11"
                :key="item.name"
              >
                <div
                  class="p-10 bg-white flex justify-center items-center mb-20"
                >
                  <img class="w-full" :src="item.icon" alt="" />
                </div>

                <div class="text-center h-40 font-semibold">
                  {{ item.name }}
                </div>
              </a-col>
            </a-row>
          </div>
          <div class="">
            <div class="text-center" style="margin-bottom: 30px">
              <div
                class="
                  inline-block
                  py-10
                  px-20
                  text-second
                  bg-black
                  rounded-full
                "
              >
                「年度风云企业提名名单」
              </div>
            </div>
            <a-row :gutter="40" class="text-sm">
              <a-col
                :span="6"
                class="mb-20"
                v-for="item in company21"
                :key="item.name"
              >
                <div
                  class="p-10 bg-white flex justify-center items-center mb-20"
                >
                  <img class="w-full" :src="item.icon" alt="" />
                </div>

                <div class="text-center h-40 font-semibold">
                  {{ item.name }}
                </div>
              </a-col>
            </a-row>
          </div>
          <div class="absolute bottom-20 right-20">
            <img
              style="width: 72px"
              src="@/assets/icons/company/奖杯.svg"
              alt=""
            />
          </div>
        </div>
        <div class="hidden desktop:block p-20 bg-background rounded relative">
          <div class="">
            <div class="text-center" style="margin-bottom: 30px">
              <div
                class="
                  inline-block
                  py-10
                  px-20
                  text-second
                  bg-black
                  rounded-full
                "
              >
                「年度先锋人物获奖名单」
              </div>
            </div>
            <a-row>
              <a-col
                :span="6"
                v-for="item in persons11"
                :key="item.name"
                class="text-center mb-40"
                style="min-height: 280px"
              >
                <div class="inline-block relative mb-20">
                  <img
                    style="width: 160px; height: 160px; object-fit: contain"
                    :src="item.icon"
                    alt=""
                  />
                  <div
                    class="
                      absolute
                      bottom-0
                      left-1/2
                      transform
                      -translate-x-1/2
                      bg-white
                      font-semibold
                      rounded-full
                    "
                    style="width: 120px; height: 40px; line-height: 40px"
                  >
                    {{ item.name }}
                  </div>
                </div>
                <div
                  style="width: 160px"
                  class="mb-10 font-semibold inline-block"
                >
                  {{ item.company }}
                </div>
                <div style="width: 160px" class="text-666 text-xs inline-block">
                  {{ item.title }}
                </div>
              </a-col>
            </a-row>
          </div>
          <div class="">
            <div class="text-center" style="margin-bottom: 30px">
              <div
                class="
                  inline-block
                  py-10
                  px-20
                  text-second
                  bg-black
                  rounded-full
                "
              >
                「年度先锋人物提名名单」
              </div>
            </div>
            <a-row>
              <a-col
                :span="6"
                v-for="item in persons21"
                :key="item.name"
                class="text-center mb-40"
                style="min-height: 280px"
              >
                <div class="inline-block relative mb-20">
                  <img
                    style="width: 160px; height: 160px; object-fit: contain"
                    :src="item.icon"
                    alt=""
                  />
                  <div
                    class="
                      absolute
                      bottom-0
                      left-1/2
                      transform
                      -translate-x-1/2
                      bg-white
                      font-semibold
                      rounded-full
                    "
                    style="width: 120px; height: 40px; line-height: 40px"
                  >
                    {{ item.name }}
                  </div>
                </div>
                <div
                  style="width: 160px"
                  class="mb-10 font-semibold inline-block"
                >
                  {{ item.company }}
                </div>
                <div style="width: 160px" class="text-666 text-xs inline-block">
                  {{ item.title }}
                </div>
              </a-col>
            </a-row>
          </div>
          <div class="absolute bottom-20 right-20">
            <img
              style="width: 72px"
              src="@/assets/icons/company/奖杯.svg"
              alt=""
            />
          </div>
        </div>
        <div class="desktop:hidden p-20 bg-background rounded relative">
          <a-select v-model="active2" class="mb-40" style="width: 280px">
            <a-select-option :value="1">年度风云企业获奖名单</a-select-option>
            <a-select-option :value="2">年度风云企业提名名单</a-select-option>
            <a-select-option :value="3">年度先锋人物获奖名单</a-select-option>
            <a-select-option :value="4">年度先锋人物提名名单</a-select-option>
          </a-select>
          <a-row :gutter="40" class="">
            <a-col
              :span="24"
              class="mb-20 text-center"
              v-for="item in list1"
              :key="item.name"
            >
              <div
                class="inline-block"
                style="width: 160px"
                v-if="[1, 2].includes(active2)"
              >
                <div
                  class="p-10 bg-white flex justify-center items-center mb-20"
                >
                  <img class="w-full" :src="item.icon" alt="" />
                </div>
                <div class="text-center h-40">
                  {{ item.name }}
                </div>
              </div>
              <div v-if="[3, 4].includes(active2)">
                <div class="inline-block relative mb-20">
                  <img
                    style="width: 160px; height: 160px; object-fit: contain"
                    :src="item.icon"
                    alt=""
                  />
                  <div
                    class="
                      absolute
                      bottom-0
                      left-1/2
                      transform
                      -translate-x-1/2
                      bg-white
                      font-semibold
                      rounded-full
                    "
                    style="width: 120px; height: 40px; line-height: 40px"
                  >
                    {{ item.name }}
                  </div>
                </div>
                <div class="mb-10 font-semibold">{{ item.company }}</div>
                <div class="text-666 text-xs">{{ item.title }}</div>
              </div>
            </a-col>
          </a-row>
          <div class="absolute bottom-20 right-20">
            <img
              style="width: 72px"
              src="@/assets/icons/company/奖杯.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </Wrapper>
    <!-- 往届回顾 -->
    <Wrapper title="往届回顾" index="07">
      <div>
        <div
          class="hidden desktop:block p-20 mb-40 bg-background rounded relative"
        >
          <div class="mb-40">
            <div class="text-center" style="margin-bottom: 30px">
              <div
                class="
                  inline-block
                  py-10
                  px-20
                  text-second
                  bg-black
                  rounded-full
                "
              >
                「年度风云企业获奖名单」
              </div>
            </div>
            <a-row :gutter="40" class="text-sm">
              <a-col
                :span="6"
                class="mb-20"
                v-for="item in company1"
                :key="item.name"
              >
                <div
                  class="p-10 bg-white flex justify-center items-center mb-20"
                >
                  <img class="w-full" :src="item.icon" alt="" />
                </div>

                <div class="text-center h-40 font-semibold">
                  {{ item.name }}
                </div>
              </a-col>
            </a-row>
          </div>
          <div class="">
            <div class="text-center" style="margin-bottom: 30px">
              <div
                class="
                  inline-block
                  py-10
                  px-20
                  text-second
                  bg-black
                  rounded-full
                "
              >
                「年度风云企业提名名单」
              </div>
            </div>
            <a-row :gutter="40" class="text-sm">
              <a-col
                :span="6"
                class="mb-20"
                v-for="item in company2"
                :key="item.name"
              >
                <div
                  class="p-10 bg-white flex justify-center items-center mb-20"
                >
                  <img class="w-full" :src="item.icon" alt="" />
                </div>

                <div class="text-center h-40 font-semibold">
                  {{ item.name }}
                </div>
              </a-col>
            </a-row>
          </div>
          <div class="absolute bottom-20 right-20">
            <img
              style="width: 72px"
              src="@/assets/icons/company/奖杯.svg"
              alt=""
            />
          </div>
        </div>
        <div class="hidden desktop:block p-20 bg-background rounded relative">
          <div class="">
            <div class="text-center" style="margin-bottom: 30px">
              <div
                class="
                  inline-block
                  py-10
                  px-20
                  text-second
                  bg-black
                  rounded-full
                "
              >
                「年度先锋人物获奖名单」
              </div>
            </div>
            <a-row>
              <a-col
                :span="6"
                v-for="item in persons1"
                :key="item.name"
                class="text-center mb-40"
                style="min-height: 280px"
              >
                <div class="inline-block relative mb-20">
                  <img
                    style="width: 160px; height: 160px; object-fit: contain"
                    :src="item.icon"
                    alt=""
                  />
                  <div
                    class="
                      absolute
                      bottom-0
                      left-1/2
                      transform
                      -translate-x-1/2
                      bg-white
                      font-semibold
                      rounded-full
                    "
                    style="width: 120px; height: 40px; line-height: 40px"
                  >
                    {{ item.name }}
                  </div>
                </div>
                <div
                  style="width: 160px"
                  class="mb-10 font-semibold inline-block"
                >
                  {{ item.company }}
                </div>
                <div style="width: 160px" class="text-666 text-xs inline-block">
                  {{ item.title }}
                </div>
              </a-col>
            </a-row>
          </div>
          <div class="">
            <div class="text-center" style="margin-bottom: 30px">
              <div
                class="
                  inline-block
                  py-10
                  px-20
                  text-second
                  bg-black
                  rounded-full
                "
              >
                「年度先锋人物提名名单」
              </div>
            </div>
            <a-row>
              <a-col
                :span="6"
                v-for="item in persons2"
                :key="item.name"
                class="text-center mb-40"
                style="min-height: 280px"
              >
                <div class="inline-block relative mb-20">
                  <img
                    style="width: 160px; height: 160px; object-fit: contain"
                    :src="item.icon"
                    alt=""
                  />
                  <div
                    class="
                      absolute
                      bottom-0
                      left-1/2
                      transform
                      -translate-x-1/2
                      bg-white
                      font-semibold
                      rounded-full
                    "
                    style="width: 120px; height: 40px; line-height: 40px"
                  >
                    {{ item.name }}
                  </div>
                </div>
                <div
                  style="width: 160px"
                  class="mb-10 font-semibold inline-block"
                >
                  {{ item.company }}
                </div>
                <div style="width: 160px" class="text-666 text-xs inline-block">
                  {{ item.title }}
                </div>
              </a-col>
            </a-row>
          </div>
          <div class="absolute bottom-20 right-20">
            <img
              style="width: 72px"
              src="@/assets/icons/company/奖杯.svg"
              alt=""
            />
          </div>
        </div>
        <div class="desktop:hidden p-20 bg-background rounded relative">
          <a-select v-model="active1" class="mb-40" style="width: 280px">
            <a-select-option :value="1">年度风云企业获奖名单</a-select-option>
            <a-select-option :value="2">年度风云企业提名名单</a-select-option>
            <a-select-option :value="3">年度先锋人物获奖名单</a-select-option>
            <a-select-option :value="4">年度先锋人物提名名单</a-select-option>
          </a-select>
          <a-row :gutter="40" class="">
            <a-col
              :span="24"
              class="mb-20 text-center"
              v-for="item in list"
              :key="item.name"
            >
              <div
                class="inline-block"
                style="width: 160px"
                v-if="[1, 2].includes(active1)"
              >
                <div
                  class="p-10 bg-white flex justify-center items-center mb-20"
                >
                  <img class="w-full" :src="item.icon" alt="" />
                </div>
                <div class="text-center h-40">
                  {{ item.name }}
                </div>
              </div>
              <div v-if="[3, 4].includes(active1)">
                <div class="inline-block relative mb-20">
                  <img
                    style="width: 160px; height: 160px; object-fit: contain"
                    :src="item.icon"
                    alt=""
                  />
                  <div
                    class="
                      absolute
                      bottom-0
                      left-1/2
                      transform
                      -translate-x-1/2
                      bg-white
                      font-semibold
                      rounded-full
                    "
                    style="width: 120px; height: 40px; line-height: 40px"
                  >
                    {{ item.name }}
                  </div>
                </div>
                <div class="mb-10 font-semibold">{{ item.company }}</div>
                <div class="text-666 text-xs">{{ item.title }}</div>
              </div>
            </a-col>
          </a-row>
          <div class="absolute bottom-20 right-20">
            <img
              style="width: 72px"
              src="@/assets/icons/company/奖杯.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </Wrapper>

    <a-modal title="参赛详情" v-model="isShowSign" okText="提交" @ok="submit">
      <a-form-model
        ref="container"
        labelAlign="left"
        :model="current"
        :rules="rules"
      >
        <a-form-model-item label="参赛方向" prop="type">
          <a-select v-model="current.type">
            <a-select-option :value="1">年度风云企业</a-select-option>
            <a-select-option :value="2">年度先锋人物</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="参与赛道" prop="track">
          <a-select v-model="current.track">
            <a-select-option
              :key="option.ID"
              :value="option.ID"
              v-for="option in Tracks"
              >{{ option.Name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-back-top>
      <img src="@/assets/icons/company/回到顶部.svg" alt="" />
    </a-back-top>
  </div>
</template>

<script>
import Wrapper from "./wrapper.vue";
import AvatarMenu from "@/router/components/AvatarMenu/index.vue";
import Avatar from "@/store/components/Avatar/index.vue";
import MessageBadge from "@/store/components/MessageBadge/index.vue";

import { getReg, deleteReg, getEvent } from "@/api1/track.js";
import moment from "moment";
import { transformCompany } from "@/utils/transform/company.js";
export default {
  components: { Wrapper, AvatarMenu, Avatar, MessageBadge },
  data() {
    return {
      isMessage: false,
      active: "",
      active1: 1,
      active2: 1,
      nav: [
        "首页",
        "活动简介",
        "奖项设置",
        "大赛进程",
        "组织架构",
        "评选方法",
        "获奖公告",
        "往届回顾",
      ],
      persons11: [
        {
          name: "陈钟浩",
          company: "上海致景信息科技有限公司",
          title: "致景科技集团高级副总裁，上海致景总裁",
          icon: require("@/assets/icons/company1/陈钟浩.png"),
        },
        {
          name: "陆金琪",
          company: "上海阿波罗机械股份有限公司",
          title: "董事长",
          icon: require("@/assets/icons/company1/陆金琪.png"),
        },
        {
          name: "褚小东",
          company: "上海华谊新材料有限公司",
          title: "党委书记、董事长",
          icon: require("@/assets/icons/company1/褚小东.png"),
        },
        {
          name: "周志杰",
          company: "上海异工同智信息科技有限公司",
          title: "CEO",
          icon: require("@/assets/icons/company1/周志杰.png"),
        },
        {
          name: "陈刚",
          company: "中云开源数据技术（上海）有限公司",
          title: "总经理兼首席技术官",
          icon: require("@/assets/icons/company1/陈刚.png"),
        },
        {
          name: "周建华",
          company: "上海霍莱沃电子系统技术股份有限公司",
          title: "董事长、首席技术官",
          icon: require("@/assets/icons/company1/周建华.png"),
        },
        {
          name: "王鹂辉",
          company: "上海航数智能科技有限公司",
          title: "总经理",
          icon: require("@/assets/icons/company1/王鹂辉.png"),
        },
        {
          name: "张亮",
          company: "上海汽车集团股份有限公司乘用车分公司",
          title: "上汽集团数据业务部副总经理 乘用车公司首席数字官（CDO）",
          icon: require("@/assets/icons/company1/张亮.png"),
        },
        {
          name: "胡永焕",
          company: "国网上海市电力公司",
          title: "物资部副主任",
          icon: require("@/assets/icons/company1/胡永焕.png"),
        },
        {
          name: "陈涛",
          company: " 上海复星医药（集团）股份有限公司",
          title: "数字科技创新部总经理",
          icon: require("@/assets/icons/company1/陈涛.png"),
        },
      ],
      persons21: [
        {
          name: "王金德",
          company: "上海质量管理科学研究院",
          title: "副院长",
          icon: require("@/assets/icons/company1/王金德.png"),
        },
        {
          name: "胡柏",
          company: "上海爱湃斯科技有限公司",
          title: "ClickPaaS创始人、CEO",
          icon: require("@/assets/icons/company1/胡柏.png"),
        },
        {
          name: "胡绍勇",
          company: "上海观安信息技术股份有限公司",
          title: "首席技术官",
          icon: require("@/assets/icons/company1/胡绍勇.png"),
        },
        {
          name: "董易",
          company: "上海飒智智能科技有限公司",
          title: "研发总监",
          icon: require("@/assets/icons/company1/董易.png"),
        },
        {
          name: "肖文凯",
          company: "申能（集团）有限公司",
          title: "科技创新中心副主任",
          icon: require("@/assets/icons/company1/肖文凯.png"),
        },
      ],
      company11: [
        {
          name: "上海外高桥造船有限公司",
          icon: require("@/assets/icons/company1/上海外高桥造船有限公司.png"),
        },
        {
          name: "申能（集团）有限公司",
          icon: require("@/assets/icons/company1/申能（集团）有限公司.png"),
        },
        {
          name: "上海航天精密机械研究所",
          icon: require("@/assets/icons/company1/上海航天精密机械研究所.png"),
        },
        {
          name: "上海微创医疗器械（集团）有限公司",
          icon: require("@/assets/icons/company1/上海微创医疗器械（集团）有限公司.png"),
        },
        {
          name: "上海思普信息技术有限公司",
          icon: require("@/assets/icons/company1/上海思普信息技术有限公司.png"),
        },
        {
          name: "上海霍莱沃电子系统技术股份有限公司",
          icon: require("@/assets/icons/company1/上海霍莱沃电子系统技术股份有限公司.png"),
        },
        {
          name: "亮风台（上海）信息科技有限公司",
          icon: require("@/assets/icons/company1/亮风台（上海）信息科技有限公司.png"),
        },
        {
          name: "上海优也信息科技有限公司",
          icon: require("@/assets/icons/company1/上海优也信息科技有限公司.png"),
        },
        {
          name: "上海万向区块链股份公司",
          icon: require("@/assets/icons/company1/上海万向区块链股份公司.png"),
        },
        {
          name: "上海电器科学研究所（集团）有限公司",
          icon: require("@/assets/icons/company1/上海电器科学研究所（集团）有限公司.png"),
        },
      ],
      company21: [
        {
          name: "上海华谊新材料有限公司",
          icon: require("@/assets/icons/company1/上海华谊新材料有限公司.png"),
        },
        {
          name: "上海工业控制安全创新科技有限公司",
          icon: require("@/assets/icons/company1/上海工业控制安全创新科技有限公司.png"),
        },
        {
          name: "国核电站运行服务技术有限公司",
          icon: require("@/assets/icons/company1/国核电站运行服务技术有限公司.png"),
        },
        {
          name: "上海湃道智能科技有限公司",
          icon: require("@/assets/icons/company1/上海湃道智能科技有限公司.png"),
        },
        {
          name: "上海航数智能科技有限公司",
          icon: require("@/assets/icons/company1/上海航数智能科技有限公司.png"),
        },
      ],
      persons1: [
        {
          name: "黄璿",
          company: "中国联合网络通信有限公司上海市分公司",
          title: "智能制造事业部总经理、智能制造研究院院长",
          icon: require("@/assets/icons/company/黄璿.png"),
        },
        {
          name: "陈明吉",
          company: "上海城投水务（集团）有限公司",
          title: "董事长、党委副书记",
          icon: require("@/assets/icons/company/陈明吉.png"),
        },
        {
          name: "廖春元",
          company: "亮风台（上海）信息科技有限公司",
          title: "董事长、CEO",
          icon: require("@/assets/icons/company/廖春元.png"),
        },
        {
          name: "谢海琴",
          company: "海尔数字科技（上海）有限公司",
          title: "总经理",
          icon: require("@/assets/icons/company/谢海琴.png"),
        },
        {
          name: "张帆",
          company: "上海电气电站集团",
          title: "智能化发展中心 副主任",
          icon: require("@/assets/icons/company/张帆.png"),
        },
        {
          name: "张小军",
          company: "上海艾络格电子技术有限公司",
          title: "总经理",
          icon: require("@/assets/icons/company/张小军.png"),
        },
        {
          name: "陆晋军",
          company: "中国电信集团有限公司",
          title: "政企信息服务事业群工业企业拓展部 总裁",
          icon: require("@/assets/icons/company/陆晋军.png"),
        },
        {
          name: "谢孟军",
          company: "上海积梦智能科技有限公司",
          title: "CEO",
          icon: require("@/assets/icons/company/谢孟军.png"),
        },
        {
          name: "刘源",
          company: "澳汰尔工程软件（上海）有限公司",
          title: "总经理",
          icon: require("@/assets/icons/company/刘源.png"),
        },
        {
          name: "曹荣根",
          company: " 鸿之微科技（上海）股份有限公司",
          title: "董事长",
          icon: require("@/assets/icons/company/曹荣根.png"),
        },
      ],
      persons2: [
        {
          name: "金宾",
          company: "安吉智能物联技术有限公司",
          title: "总经理",
          icon: require("@/assets/icons/company/金宾.png"),
          company: "安吉智能物联技术有限公司",
          title: "总经理",
        },
        {
          name: "傅源",
          company: "上海优也信息科技有限公司",
          title: "董事长、联合创始人",
          icon: require("@/assets/icons/company/傅源.png"),
          company: "上海优也信息科技有限公司",
          title: "董事长、联合创始人",
        },
        {
          name: "潘晶",
          company: "上海钛米机器人股份有限公司",
          title: "董事长、CEO",
          icon: require("@/assets/icons/company/潘晶.png"),
          company: "上海钛米机器人股份有限公司",
          title: "董事长、CEO",
        },
        {
          name: "张建政",
          company: "上海飒智智能科技有限公司",
          title: "总经理",
          icon: require("@/assets/icons/company/张建政.png"),
          company: "上海飒智智能科技有限公司",
          title: "总经理",
        },
        {
          name: "张启亮",
          company: "上海徐工汉云工业互联网有限公司",
          title: "总经理",
          icon: require("@/assets/icons/company/张启亮.png"),
          company: "上海徐工汉云工业互联网有限公司",
          title: "总经理",
        },
      ],
      company1: [
        {
          name: "中云开源数据技术（上海）有限公司",
          icon: require("@/assets/icons/company/1.png"),
        },
        {
          name: "上海致景信息科技有限公司",
          icon: require("@/assets/icons/company/2.png"),
        },
        {
          name: "中国商用飞机有限责任公司上海飞机设计研究院",
          icon: require("@/assets/icons/company/3.png"),
        },
        {
          name: "国网上海市电力公司",
          icon: require("@/assets/icons/company/4.png"),
        },
        {
          name: "威马汽车科技集团有限公司",
          icon: require("@/assets/icons/company/5.png"),
        },
        {
          name: "中国联合网络通信有限公司上海市分公司",
          icon: require("@/assets/icons/company/6.png"),
        },
        {
          name: "上海电气集团数字科技有限公司",
          icon: require("@/assets/icons/company/7.png"),
        },
        {
          name: "上海宝信软件股份有限公司",
          icon: require("@/assets/icons/company/8.png"),
        },
        {
          name: "上海市北高新（集团）有限公司",
          icon: require("@/assets/icons/company/9.png"),
        },
        {
          name: "上海临港松江创业投资管理有限公司",
          icon: require("@/assets/icons/company/10.png"),
        },
      ],
      company2: [
        {
          name: "上海宾通智能科技有限公司",
          icon: require("@/assets/icons/company/提名1.png"),
        },
        {
          name: "上海城投水务（集团）有限公司",
          icon: require("@/assets/icons/company/提名2.png"),
        },
        {
          name: "上海扩博智能技术有限公司",
          icon: require("@/assets/icons/company/提名3.png"),
        },
        {
          name: "竹间智能科技（上海）有限公司",
          icon: require("@/assets/icons/company/提名4.png"),
        },
        {
          name: "锱云(上海)物联网科技有限公司",
          icon: require("@/assets/icons/company/提名5.png"),
        },
      ],
      state: 0, // 0 未开始 1 申请材料征集中 2 材料初审中 3 专家复审中 4 现场路演及决赛 5 获奖名单公告中 6 颁奖中 7 颁奖结束
      time: {
        day: 0,
        hour: 0,
        minute: 0,
        second: 0,
      },
      tracks: [],
      trackList: [
        {
          title: "领军企业",
          content:
            "重点征集围绕大型制造企业的工业互联网创新应用实践，工业互联网与5G、AI、区块链深度融合应用，以及数字化转型新思路、新模式、新应用；征集范围包括国资国企、外资、民营企业等大型制造企业。",
          id: 1,
        },
        {
          title: "自主创新",
          id: 2,
          content:
            "重点征集工业互联网网络、平台、安全、标识解析、工业软件五个方向，尤其是拥有自主知识产权、自主研发可控等工业科技公司；征集范围包括国资国企、外资、民营企业等各种类型企业。",
        },
        {
          title: "工赋新锐",
          id: 3,
          content:
            "重点征集工业互联网赛道上拥有先进技术、自研平台（含产品、解决方案）及有核心能力、客户口碑良好的创业公司；征集范围包括2016-2021年在上海注册成立，以及落户上海的工业互联网赛道创新创业公司。",
        },
        {
          title: "公共服务",
          id: 4,
          content:
            "征集范围包括但不限于咨询机构、产融公司、两化融合贯标服务机构、产业园区等优秀服务机构。",
        },
        {
          title: "领军人物",
          id: 5,
          content:
            "以“责任、引领”为关键词，致力于推动上海工业互联网2.0创新发展，在试点示范、模式创新、生态营造等对工业互联网产业生态作出突出贡献，引领行业发展方向的杰出人物。",
        },
        {
          title: "首席数字官",
          id: 6,
          content:
            "以“数字、创新”为关键词，致力于推动企业内部以及产业链的数据流动融通、强化数据管理和融合创新发展的先进数字官。",
        },
        {
          title: "技术专家",
          id: 7,
          content:
            "以“创新、担当”为关键词，长期在关键岗位上钻研技术，致力于通过技术创新实现企业跨越式发展的先进个人。",
        },
      ],
      isShowSign: false,
      // 赛道报名详情
      current: {},
      times: [
        ["2021/11/01", "2021/12/03"],
        ["2021/12/03", "2021/12/26"],
        ["2021/12/27", "2021/01/14"],
        ["2021/01/15", "2032/12/22"],
        ["2032/12/23", "2032/12/21"],
        ["2032/12/22", "2022/12/30"],
        // ["2021/12/31", "2021/12/32"],
      ],
      times1: [
        ["2021/11/01", "2021/11/28"],
        ["2021/11/29", "2021/11/30"],
        ["2021/12/01", "2021/12/08"],
        ["2021/12/09", "2021/12/15"],
        ["2021/12/16", "2021/12/25"],
        ["2021/12/26", "2021/12/27"],
      ],
      Scale: [
        {
          ID: 1,
          Name: "大型",
        },
        {
          ID: 2,
          Name: "中型",
        },
        {
          ID: 3,
          Name: "小型",
        },
      ],
      rules: {
        type: {
          required: true,
          message: "请选择参赛方向",
          trigger: ["change", "blur"],
        },
        track: {
          required: true,
          message: "请选择参与赛道",
          trigger: ["change", "blur"],
        },
      },
    };
  },
  computed: {
    list() {
      if (this.active1 === 1) {
        return this.company1;
      } else if (this.active1 === 2) {
        return this.company2;
      } else if (this.active1 === 3) {
        return this.persons1;
      } else if (this.active1 === 4) {
        return this.persons2;
      } else {
        return [];
      }
    },
    list1() {
      if (this.active2 === 1) {
        return this.company11;
      } else if (this.active2 === 2) {
        return this.company21;
      } else if (this.active2 === 3) {
        return this.persons11;
      } else if (this.active2 === 4) {
        return this.persons21;
      } else {
        return [];
      }
    },
    isLogin() {
      return this.$store.state.userInfo && this.$store.state.userInfo.id;
    },
    options() {
      return this.$store.state.options;
    },
    Tracks() {
      let temp = [];
      if (this.current.type === 1) {
        temp = this.options.Track.slice(0, 4);
      } else if (this.current.type === 2) {
        temp = this.options.Track.slice(4);
      }

      return temp;
    },
    company() {
      return transformCompany(this.$store.state.user.COMPANY_INFO);
    },
    reg() {
      return this.$store.state.reg;
    },
  },
  created() {
    if (this.timer) clearInterval(this.timer);
    this.getState();
    this.timer = setInterval(() => {
      this.getState();
    }, 1000);

    if (this.isLogin) {
      getEvent().then(() => {
        this.$store.dispatch("getReg");
      });
    }
  },
  methods: {
    navTo(e) {
      this.active = e;
      const el = document.getElementById(e);
      el.scrollIntoView({ behavior: "smooth" });
    },
    getState() {
      const arr = this.times;
      const d = localStorage.getItem("date");
      const time = d ? new Date(d).getTime() : new Date().getTime();
      for (let i = 0; i < arr.length; i++) {
        const start = new Date(arr[i][0]).getTime();
        let end;

        if (i === arr.length - 1) {
          end = new Date(arr[i][1]).getTime();
        } else {
          end = new Date(arr[i + 1][0]).getTime();
        }
        if (time >= start && time < end) {
          this.state = i + 1;
          break;
        }
      }
      if (!this.state) {
        const date1 = new Date(arr[0][0]);
        let seconds = (date1.getTime() - time) / 1000;

        const day = Math.floor(seconds / (60 * 60 * 24));
        seconds = seconds % (60 * 60 * 24);
        const hour = Math.floor(seconds / (60 * 60));
        seconds = seconds % (60 * 60);
        const minute = Math.floor(seconds / 60);
        seconds = Math.floor(seconds % 60);

        this.$set(this.time, "day", day);
        this.$set(this.time, "hour", hour.toString().padStart(2, 0));
        this.$set(this.time, "minute", minute.toString().padStart(2, 0));
        this.$set(this.time, "second", seconds.toString().padStart(2, 0));
      } else if (this.state === 1) {
        const date1 = new Date(arr[1][0]);
        let seconds = (date1.getTime() - time) / 1000;

        const day = Math.floor(seconds / (60 * 60 * 24));
        seconds = seconds % (60 * 60 * 24);
        const hour = Math.floor(seconds / (60 * 60));
        seconds = seconds % (60 * 60);
        const minute = Math.floor(seconds / 60);
        seconds = Math.floor(seconds % 60);

        this.$set(this.time, "day", day);
        this.$set(this.time, "hour", hour.toString().padStart(2, 0));
        this.$set(this.time, "minute", minute.toString().padStart(2, 0));
        this.$set(this.time, "second", seconds.toString().padStart(2, 0));
      }
    },
    join(data = {}) {
      if (!this.isLogin) {
        return this.$message.warn("需登录才可报名哦~");
      }
      let { id, isJoined } = data;

      if (id) {
        let track = id,
          type;
        if ([1, 2, 3, 4].includes(track)) {
          type = 1;
        } else if ([5, 6, 7].includes(track)) {
          type = 2;
        }

        this.current = {
          track,
          type,
        };
      }

      if (isJoined && isJoined.status === 1) {
        const onOk = () => {
          this.$router.push({
            name: "home-requirement-apply",
            query: { ...this.current },
          });
        };
        this.$confirm({
          title: `${
            this.current.type === 1 ? "年度风云企业" : "年度先锋人物"
          }-${isJoined.name}已参赛，是否需要修改参赛信息？`,
          onOk,
        });
      } else {
        this.isShowSign = true;
      }
    },
    submit() {
      this.$refs.container.validate((valid) => {
        if (valid) {
          this.isShowSign = false;
          const isJoined = this.tracks.find(
            (track) => track.id === this.current.track
          );
          const onOk = () => {
            this.$router.push({
              name: "home-requirement-apply",
              query: { ...this.current },
            });
          };
          if (isJoined && isJoined.status === 1) {
            this.$confirm({
              title: `${
                this.current.type === 1 ? "年度风云企业" : "年度先锋人物"
              }-${isJoined.name}已参赛，是否需要修改参赛信息？`,
              onOk,
            });
          } else {
            onOk();
          }
        }
      });
    },
    cancel(e) {
      const onOk = () => {
        deleteReg(e).then(() => {
          this.$message.success(`取消${e.name}报名成功`);
          this.$store.dispatch("getReg");
        });
      };
      this.$confirm({
        title: "是否确定取消报名，取消之后信息不可恢复哦",
        okText: "是",
        okType: "danger",
        cancelText: "否",
        onOk,
      });
    },
    join1() {
      this.$message.warn("抱歉手机端暂不支持报名，如需报名请前往电脑端报名");
    },
  },
  filters: {
    getDate(val) {
      const arr = val.split("/");
      arr.shift();
      return `${arr[0]}月${arr[1]}号`;
    },
  },
  watch: {
    reg: {
      handler(val) {
        this.tracks = val.map((item) => {
          let status = item.Status,
            detail = item.Detail,
            name = detail.Track.Name,
            id = detail.Track.ID,
            fault;

          if (status === 0 && !this.isMessage) {
            this.isMessage = true;
            let notTip = this.$store.state.notTip;
            if (!notTip) {
              this.$message.warn(
                "您报名的部分赛道未上传盖章版本哦，可在个人中心完善资料上传"
              );
              this.$store.commit("setNotTip", true);
            }
          }
          console.log(this.state, status, item.Detail.Track.Name);
          switch (status) {
            case 0:
              fault = this.state > 1;
              break;
            case 1:
              fault = this.state > 1 ? (this.state === 2 ? 2 : 1) : 0;
              break;
            case 10:
              fault = this.state > 2;
              break;
            case 20:
              fault = this.state > 3;
              break;
            case 30:
              fault = this.state > 4;
              break;
          }

          return {
            name,
            status,
            id,
            ID: item.ID,
            fault,
          };
        });
      },
      // immediate: true,
    },
  },
};
</script>

<style lang="less" scoped>
.item {
  &:hover,
  &.active {
    font-size: 20px;
    border-color: #fff;
  }
}
.overflow-hidden:hover {
  .opacity-0 {
    opacity: 1;
  }
}
</style>